/* You can add global styles to this file, and also import other style files */

@import 'theme.scss';

$primary: map-get($angular-default-theme, primary);
$warn: map-get($angular-default-theme, warn);

html, body { min-height: 100%; height: 100% }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-bold {
  font-weight: bold !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.mat-dialog-actions {
  justify-content: flex-end;
}

.list-item-active {
  font-weight: bold;
  color: mat-color($angular-accent, darker) !important;
}

.mat-button-base {
  .mat-icon {
    display: inline-flex;
    align-items: center;
  }
}

.tab-icon {
  margin-right: 8px;
}

.spacer {
  flex: 1 1 auto;
}

[hidden] {
  display: none !important;
}

.text-decoration-none {
  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.fc-license-message {
  display: none;
}

.image-modal {
  max-width: unset !important;
  width: 100%;
  height: 100%;
  .mat-dialog-container {
    max-width: 100vw;
    max-height: 100vh;
    height: 100%;
    width: 100%;
    padding-bottom: 0;

    .mat-dialog-content {
      max-height: unset !important;
    }
  }
}

.right-static-suffix {
  position: absolute !important;
  right: 20px;
  top: -20px;
}

.right-static-suffix-button {
  position: absolute !important;
  right: 8px;
  top: -30px;
}

// fullcalendar js
.fc-day-today {
  background-color: #E8F5E9 !important;
}

.fc-event-main-frame {
  line-height: initial !important;
}

.mat-fab {
  transform: scale(0.7) !important;
}

// material design fixes
.mat-select-trigger {
  height: fit-content;
}

.mat-small {
  color: #4e4e4e !important;
}

.mat-button-toggle-checked {
  border: 1px solid mat-color($primary);
  background: transparent;
}

.mat-snack-bar-container {
  padding: 0 !important;
  color: black;
}
