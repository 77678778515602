@use '@angular/material' as mat;
@import "@angular/material/theming";
@import 'theme-colors';
@include mat.core();

@include mat.all-component-themes($angular-default-theme);

.mat-app-background {
  background: white;
  min-height: 100vh;
}

a {
  color: #00000099;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
